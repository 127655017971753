var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Deskundigenregister aanvragen")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "expert-register-tab-" + index,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === organization.id },
                          attrs: { href: "#" + organization.id }
                        },
                        [_vm._v(_vm._s(organization.name))]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key:
                                "expert-register-tab-content-" +
                                organization.name,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md12: "" } },
                                        [
                                          _c(
                                            "ExpertRegisterSubmissionsDatatable",
                                            {
                                              attrs: {
                                                groupByUser: true,
                                                organization: _vm.$store.state
                                                  .isServiceOrganization
                                                  ? _vm.activeTab
                                                  : ""
                                              }
                                            }
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }