var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Overzicht Deskundigenregister")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "expert-register-tab-" + index,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === organization.id },
                          attrs: { href: "#" + organization.id }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key:
                                "expert-register-tab-content-" +
                                organization.name,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel elementPanel--user"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("DataTable", {
                                            ref: "expertRegisterDatatable",
                                            refInFor: true,
                                            attrs: {
                                              options:
                                                _vm.expertRegisterDatatableTableOptions,
                                              visibility:
                                                _vm.expertRegisterDatatableVisibilityOptions
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "filters",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            wrap: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "custom-filter custom-filter--autocomplete",
                                                              attrs: { xs3: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "ReportSkillFilter",
                                                                {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "Skill",
                                                                    canSelectAll: true
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .filters
                                                                        .skills,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filters,
                                                                        "skills",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "filters.skills"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "custom-filter custom-filter--autocomplete",
                                                              attrs: { xs3: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "ExpertSubmissionStatusFilter",
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .filters
                                                                        .submission_status,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filters,
                                                                        "submission_status",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "filters.submission_status"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "custom-filter custom-filter--autocomplete",
                                                              attrs: { xs3: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "ReportLevelFilter",
                                                                {
                                                                  attrs: {
                                                                    canSelectAll: true
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .filters
                                                                        .levels,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filters,
                                                                        "levels",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "filters.levels"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "custom-filter custom-filter--autocomplete",
                                                              attrs: { xs3: "" }
                                                            },
                                                            [
                                                              _c(
                                                                "ExpertSubmissionFteFilter",
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .filters
                                                                        .available_fte,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filters,
                                                                        "available_fte",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "filters.available_fte"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }