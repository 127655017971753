import { cloneDeep } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Organization } from '@/models/Organization';
import { ReportStatusMap, excludePlanningStatuses } from '@/support/ReportStatus';
import ReportLevelFilter from '@/components/filters/report-level-filter/ReportLevelFilter.vue';
import ExpertSubmissionStatusFilter from '@/components/filters/expert-submission-status-filter/ExpertSubmissionStatusFilter.vue';
import ExpertSubmissionFteFilter from '@/components/filters/expert-submission-fte-filter/ExpertSubmissionFteFilter.vue';
import { ExperienceSubmission, statusColors, statusLabels } from '@/models/ExperienceSubmission';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { User, UserRole } from '@/models/User';
import { Skill } from '@/models/Skill';

@Component<ExpertRegister>({
  components: {
    ReportLevelFilter,
    ExpertSubmissionStatusFilter,
    ExpertSubmissionFteFilter,
  },
})
export default class ExpertRegister extends Vue {
  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Optional @PropSyncs
  // #endregion

  // #region Class properties

  protected organizations: Organization[] | null = null;

  protected activeTab = '';

  protected reportStatusMap: ReportStatusMap[] = excludePlanningStatuses();

  protected filters: ExpertRegisterFilters = {
    is_expert_register_user: true,
  };

  protected totalAmount = 0;

  // #endregion

  // #region Plugin properties

  public $pageTitle = 'Deskundigenregister Overzicht';

  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    await this.getOrganizations();
  }

  // #endregion

  // #region Class methods: Handlers

  // #endregion

  // #region Class methods: Helpers

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Deskundigenregister' }],
    });
  }

  protected getStatusColor(key: string): string {
    return statusColors[key];
  }

  protected getStatusLabel(key: string): string {
    return statusLabels[key];
  }

  // #endregion

  // #region Async methods

  protected async getOrganizations(): Promise<void> {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .getAllExperts()
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
          return [];
        });
      if (this.organizations && ! this.activeTab) {
        this.activeTab = this.organizations[0].id || '';
      }

      return;
    }

    this.activeTab = this.$store.state.Auth.organization.id;
    this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
  }

  // #endregion

  // #region Getters & Setters

  protected get expertRegisterDatatableTableOptions(): TableOptions {
    return {
      model: new User()
        .dmz(this.activeTab ? this.activeTab : this.$store.state.Auth.organization)
        .include(['last_submission', 'skills'])
        .filter(this.tableFilters),
      headers: [
        {
          text: 'Naam',
          value: 'name',
          sortable: {
            key: 'name',
            order: 'ASC',
          },
          action: `${this.tableRouterLinkUserUrlPattern}&tab=tab-deskundige-register-submissions`,
        },
        {
          text: 'Niveau',
          value: 'level',
        },
        {
          text: 'Competenties',
          value: 'skills',
          width: '50%',
          transform: (skills: Skill[]) => (skills
            ? skills.map((skill: Skill) => `<span class="statusPill tw-inline-block tw-mb-4" style="background: ${skill.color};">${skill.name}</span>`).join(' ')
            : ''),
        },
        {
          text: 'FTE',
          value: 'available_fte',
        },
        {
          text: 'Status aanvraag',
          value: 'last_submission',
          transform: (lastSubmission: ExperienceSubmission) => (lastSubmission
            ? `<span class="statusPill statusBackgroundColor--${statusColors[lastSubmission.status || ''] || ''}">${statusLabels[lastSubmission.status || ''] || ''}</span>`
            : ''),
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: `${this.tableRouterLinkUserUrlPattern}&tab=tab-deskundige-register-submissions`,
          tooltip: 'Bekijken',
        },
      ],
      sort: {
        key: 'name',
        order: 'ASC',
      },
    };
  }

  protected get expertRegisterDatatableVisibilityOptions(): TableVisibility {
    return {
      checkboxes: false,
      title: false,
    };
  }

  protected get tableRouterLinkUserUrlPattern(): string {
    return this.$store.state.isServiceOrganization
      ? `/expert-user/{id}?organization=${this.activeTab}`
      : `/users/{id}?organization=${this.activeTab}`;
  }

  protected get tableFilters(): ExpertRegisterFilters {
    return this.filters;
  }

  // #endregion

  // #region @Watchers

  @Watch('$route')
  public routeChanged() {
    this.emitBreadcrumb();
  }
  // #endregion
}

// #region Enums
// #endregion

// #region Types
// #endregion

// #region Interfaces

interface ExpertRegisterFilters {
  type?: string;
  skills?: string[];
  submission_status?: string[];
  levels?: string[];
  available_fte?: number[];
  is_expert_register_user: boolean;
}

// #endregion
