import { cloneDeep } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import ExpertRegisterSubmissionsDatatable from '@/views/ExpertRegisterSubmissions/ExpertRegisterSubmissionsDatatable/ExpertRegisterSubmissionsDatatable.vue';
import { Organization } from '@/models/Organization';

@Component<ExpertRegisterSubmissions>({
  components: {
    ExpertRegisterSubmissionsDatatable,
  },
})
export default class ExpertRegisterSubmissions extends Vue {
  public $pageTitle = 'Deskundigenregister aanvragen';

  protected organizations: Organization[] | null = null;

  protected activeTab = '';

  protected async mounted() {
    await this.getOrganizations();
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .getAllExperts();
      if (this.organizations && ! this.activeTab) {
        this.activeTab = this.organizations[0].id || '';
      }
    } else {
      this.activeTab = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Deskundigenregister aanvragen' },
        ],
      });
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
