import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

@Component<ExpertSubmissionFteFilter>({})
export default class ExpertSubmissionFteFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop()
  protected placeholder!: string;

  @Prop()
  protected filters!: any;

  protected defaultFilters: any = {};

  @Prop()
  protected label!: string;

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected selectedFtes: any[] = [];

  protected ftes: fteItem[] = [
    {
      key: 0.0,
      name: '0.0',
    }, {
      key: 0.1,
      name: '0.1',
    }, {
      key: 0.2,
      name: '0.2',
    }, {
      key: 0.3,
      name: '0.3',
    }, {
      key: 0.4,
      name: '0.4',
    }, {
      key: 0.5,
      name: '0.5',
    }, {
      key: 0.6,
      name: '0.6',
    }, {
      key: 0.7,
      name: '0.7',
    }, {
      key: 0.8,
      name: '0.8',
    }, {
      key: 0.9,
      name: '0.9',
    }, {
      key: 1.0,
      name: '1.0',
    },
  ]

  protected handleChange() {
    this.$emit('input', this.selectedFtes);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedFtes = [];
    } else {
      this.selectedFtes = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.defaultFilters = { ...this.filters, ...this.defaultFilters };
  }
}

interface fteItem {
  key?: number;
  name?: string;
}
