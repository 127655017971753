import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { UserLevelItem, UserLevels } from '@/models/User';

@Component<ReportLevelFilter>({})
export default class ReportLevelFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop({ default: false })
  protected canSelectAll!: boolean;

  protected userLevels: UserLevelItem[] = [];

  protected selectedUserLevels = [];

  protected selectAll = false;

  protected handleChange() {
    this.$emit('input', this.selectedUserLevels);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedUserLevels = [];
    } else {
      this.selectedUserLevels = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.userLevels = UserLevels;
  }

  @Watch('selectAll')
  public selectAllChanged(to: any, from: any) {
    if (this.selectAll) {
      this.$set(this, 'selectedUserLevels', this.userLevels.map((userLevel: UserLevelItem) => userLevel.value || ''));
    } else {
      this.$set(this, 'selectedUserLevels', []);
    }

    this.handleChange();
  }
}
